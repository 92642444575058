"use client";

import React, { useEffect } from 'react';
import BugsnagPerformance from '@bugsnag/browser-performance'

const BugsnagInitializer: React.FC = () => {
  useEffect(() => {
    
    if (typeof window !== 'undefined') {
 
      const NEXT_PUBLIC_ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT;
      const NEXT_PUBLIC_VERSION = process.env.NEXT_PUBLIC_VERSION;
      if (NEXT_PUBLIC_ENVIRONMENT === 'production' || NEXT_PUBLIC_ENVIRONMENT === 'development') {

        const Bugsnag = require('@bugsnag/js');
        const BugsnagPluginReact = require('@bugsnag/plugin-react');
 
         if (typeof BugsnagPerformance.start === 'function') {
          BugsnagPerformance.start({ apiKey: '17df7498d86607d610b63befafaeff15', appVersion: NEXT_PUBLIC_VERSION });
        } else {
          console.error('BugsnagPerformance.start is not a function');
        }

        Bugsnag.start({
          apiKey: '17df7498d86607d610b63befafaeff15',
          plugins: [new BugsnagPluginReact(React)],
          appVersion: NEXT_PUBLIC_VERSION,
        });
      }
    }
  }, []);

  return null;
};

export default BugsnagInitializer;