import { paramCase } from 'src/utils/change-case';

import { _id, _postTitles } from 'src/_mock/assets';
import { Paths,   } from 'src/types/common';
import { PermissionsEnum } from 'src/enums/permissions-enum.enum';
      // ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};
 
// ----------------------------------------------------------------------
 export const paths: Paths = {
  zoneStore:  'https://mui.com/store/items/zone-landing-page/',
  figmaUrl: 'https://www.figma.com/file/hjxMnGUJCjY7pX8lQbS7kn/%5BPreview%5D-Minimal-Web.v5.4.0?type=design&node-id=0-1&mode=design&t=2fxnS70DuiTLGzND-0',
  minimalStore: 'https://minimals.cc/store',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/error/403',
  page404: '/error/404',
  page500: '/error/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/hjxMnGUJCjY7pX8lQbS7kn/%5BPreview%5D-Minimal-Web.v5.4.0?type=design&node-id=0-1&mode=design&t=2fxnS70DuiTLGzND-0',
  product: { 
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id: string) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {  
    root: `/post`, 
    details: (title: string) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // DASHBOARD
  dashboard: {  
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    // permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking q`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    

    sitemap:{
      isVisible: true,
      nameKey: 'app:sitemap',
      icon: 'mdi:link',
      permissions: [],
      root: `${ROOTS.DASHBOARD}/site-map`,
   
    }, 

    /* Admin Access ******************************************************************* */
    userBalanceChange: {
      isVisible: true,
      nameKey: 'admin:user_balance_change.user_balance_changes',
      icon: 'mdi:cash-multiple',
      permissions: [PermissionsEnum.admin_access_userBalanceChanges_read],
      root: `${ROOTS.DASHBOARD}/user-balance-change`,
      details: (id: string) => `${ROOTS.DASHBOARD}/user-balance-change/${id}`,
      },
    user: {
      isVisible: true,
      nameKey: 'admin:user.users',
      icon: 'mdi:user',
      permissions: [PermissionsEnum.admin_access_users_read],
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      details: (id: string) => `${ROOTS.DASHBOARD}/user/${paramCase(id)}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      expensePayment: (userId: string) => `${ROOTS.DASHBOARD}/user/${userId}/expense-payment`,

    }, 
    role: {  
      isVisible: true,
      nameKey: 'admin:role.roles',
      icon: 'mdi:lock',
      permissions: [PermissionsEnum.admin_access_roles_read],
      root: `${ROOTS.DASHBOARD}/role`,
      new: `${ROOTS.DASHBOARD}/role/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/role/${paramCase(id)}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/role/${id}/edit`,
    },
    permission: {
      isVisible: true,
      nameKey: 'admin:permission.permissions',
      icon: 'mdi:user',
      permissions: [PermissionsEnum.admin_access_permissions_read],
      root: `${ROOTS.DASHBOARD}/permission`,
      new: `${ROOTS.DASHBOARD}/permission/new`,
      details: (slug: string) => `${ROOTS.DASHBOARD}/permission/${paramCase(slug)}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/permission/${id}/edit`,
    },

    /* Other ******************************************************************* */
    other: {
      isVisible: true,
      nameKey: 'common:others',
      icon: 'mdi:menu',
      permissions: [],
      city: {
        isVisible: true,
        nameKey: 'other:city.cities',
        icon: 'mdi:city',
        permissions: [PermissionsEnum.other_cities_read],
        root: `${ROOTS.DASHBOARD}/city`,
        new: `${ROOTS.DASHBOARD}/city/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/city/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/city/${id}/edit`,
      },
      country: {
        isVisible: true,
        nameKey: 'other:country.countries',
        icon: 'mdi:globe',
        permissions: [PermissionsEnum.other_countries_read],
        root: `${ROOTS.DASHBOARD}/country`,
        new: `${ROOTS.DASHBOARD}/country/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/country/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/country/${id}/edit`,
      },
      currency: {
        isVisible: true,
        nameKey: 'other:currency.currencies',
        icon: 'mdi:currency-usd',
        permissions: [PermissionsEnum.other_currencies_read],
        root: `${ROOTS.DASHBOARD}/currency`,
        new: `${ROOTS.DASHBOARD}/currency/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/currency/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/currency/${id}/edit`,
      },
      language: {
        isVisible: true,
        nameKey: 'other:language.languages',
        icon: 'mdi:language',
        permissions: [PermissionsEnum.other_languages_read],
        root: `${ROOTS.DASHBOARD}/language`,
        new: `${ROOTS.DASHBOARD}/language/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/language/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/language/${id}/edit`,
      },
      region: {
        isVisible: true,
        nameKey: 'other:region.regions',
        icon: 'mdi:globe',
        permissions: [PermissionsEnum.other_regions_read],
        root: `${ROOTS.DASHBOARD}/region`,
        new: `${ROOTS.DASHBOARD}/region/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/region/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/region/${id}/edit`,
      },

    },

    /* Company ******************************************************************* */
    company: {
      isVisible: true,
      nameKey: 'company:company.companies',
      icon: 'mdi:folder',
      permissions: [PermissionsEnum.company_companies_read],
     
      company: {
        isVisible: true,
        nameKey: 'company:company.companies',
        icon: 'mdi:company',
        permissions: [PermissionsEnum.company_companies_read],
        root: `${ROOTS.DASHBOARD}/company`,
        new: `${ROOTS.DASHBOARD}/company/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company/${id}/edit`,
      },

      companyWarehouse: {
        isVisible: true,
        nameKey: 'company:company_warehouse.company_warehouses',
        icon: 'mdi:warehouse',
        permissions: [PermissionsEnum.company_companyWarehouses_read],
        root: `${ROOTS.DASHBOARD}/company-warehouse`,
        new: `${ROOTS.DASHBOARD}/company-warehouse/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse/${id}/edit`,
      },
      companyWarehouseProduct: {
        isVisible: true,
        nameKey: 'company:company_warehouse_product.company_warehouse_product',
        icon: 'mdi:warehouse',
        permissions: [PermissionsEnum.company_companyWarehouseProducts_read],
        root: `${ROOTS.DASHBOARD}/company-warehouse-product`,
        cards: `${ROOTS.DASHBOARD}/company-warehouse-product/cards`,
        new: `${ROOTS.DASHBOARD}/company-warehouse-product/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-product/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-product/${id}/edit`,
      },
      companyWarehouseDamagedProduct: {
        isVisible: true,
        nameKey: 'company:company_warehouse_damaged_product.company_warehouse_damaged_product',
        icon: 'mdi:warehouse',
        permissions: [PermissionsEnum.company_companyWarehouseDamagedProducts_read],
        root: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product`,
        cards: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product/cards`,
        new: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product/${id}/edit`,
      },
      companyWarehouseDamagedProductCategory: {
        isVisible: true,
        nameKey: 'company:company_warehouse_damaged_product_category.company_warehouse_damaged_product_category',
        icon: 'mdi:warehouse',
        permissions: [PermissionsEnum.company_companyWarehouseDamagedProductCategories_read],
        root: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-category`,
        new: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-category/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-category/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-category/${id}/edit`,
      },
      companyClient: {
        isVisible: true,
        nameKey: 'company:company_client.company_clients',
        icon: 'mdi:store',
        permissions: [PermissionsEnum.company_companyClients_read],
        root: `${ROOTS.DASHBOARD}/company-client`,
        new: `${ROOTS.DASHBOARD}/company-client/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-client/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-client/${id}/edit`,
        addClient: `${ROOTS.DASHBOARD}/company-client/add-client`,
        salesInvoicePayment: (companyClientId: string) => `${ROOTS.DASHBOARD}/company-client/${companyClientId}/sales-invoice-payment`,
      },
      companyClientCategory: {
        isVisible: true,
        nameKey: 'company:company_client_category.company_client_category',
        icon: 'mdi:store',
        permissions: [PermissionsEnum.company_companyClientCategories_read],  
        root: `${ROOTS.DASHBOARD}/company-client-category`,
        new: `${ROOTS.DASHBOARD}/company-client-category/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-client-category/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-client-category/${id}/edit`,
      },
      companyClientChain: {
        isVisible: true,
        nameKey: 'company:company_client_chain.company_client_chain',
        icon: 'mdi:link',
        permissions: [PermissionsEnum.company_companyClientChains_read],
        root: `${ROOTS.DASHBOARD}/company-client-chain`,
        new: `${ROOTS.DASHBOARD}/company-client-chain/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-client-chain/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-client-chain/${id}/edit`,
        reorder: `${ROOTS.DASHBOARD}/company-client-chain/reorder`,
      },

      companyClientGroup: {
        isVisible: true,
        nameKey: 'company:company_client_group.company_client_group',
        icon: 'mdi:user',
        permissions: [PermissionsEnum.company_companyClientGroups_read],
        root: `${ROOTS.DASHBOARD}/company-client-group`,
        new: `${ROOTS.DASHBOARD}/company-client-group/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-client-group/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-client-group/${id}/edit`,
      },
      companyZone: {
        isVisible: true,
        nameKey: 'company:company_zone.company_zone',
        icon: 'mdi:company',
        permissions: [PermissionsEnum.company_companyZones_read],
        root: `${ROOTS.DASHBOARD}/company-zone`,
        new: `${ROOTS.DASHBOARD}/company-zone/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-zone/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-zone/${id}/edit`,
      },
      companyPosition: {
        isVisible: true,
        nameKey: 'company:company_position.company_position',
        icon: 'mdi:company',
        permissions: [PermissionsEnum.company_companyPositions_read],
        root: `${ROOTS.DASHBOARD}/company-position`,
        new: `${ROOTS.DASHBOARD}/company-position/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-position/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-position/${id}/edit`,
      },
      companyClientPosition: {
        isVisible: true,
        nameKey: 'company:company_client_position.company_client_position',
        icon: 'mdi:company',
        permissions: [PermissionsEnum.company_companyClientPositions_read],
        root: `${ROOTS.DASHBOARD}/company-client-position`,
        new: `${ROOTS.DASHBOARD}/company-client-position/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-client-position/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-client-position/${id}/edit`,
      },
      companyWarehouseDamagedProductDisposeCategory: {
        isVisible: true,  
        nameKey: 'company:company_warehouse_damaged_product_dispose_category.company_warehouse_damaged_product_dispose_category',
        icon: 'mdi:warehouse',
        permissions: [PermissionsEnum.company_companyWarehouseDamagedProductDisposeCategories_read],
        root: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-dispose-category`,
        new: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-dispose-category/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-dispose-category/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-dispose-category/${id}/edit`,
      },
      companyWarehouseDamagedProductRestoreCategory: {
        isVisible: true,  
        nameKey: 'company:company_warehouse_damaged_product_restore_category.company_warehouse_damaged_product_restore_category',
        icon: 'mdi:warehouse',
        permissions: [PermissionsEnum.company_companyWarehouseDamagedProductRestoreCategories_read],
        root: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-restore-category`,
        new: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-restore-category/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-restore-category/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-restore-category/${id}/edit`,
      },
      companyWarehouseDamagedProductDispose: {
        isVisible: true,
        nameKey: 'company:company_warehouse_damaged_product_dispose.company_warehouse_damaged_product_dispose',
        icon: 'mdi:warehouse',
        permissions: [PermissionsEnum.company_companyWarehouseDamagedProductDisposes_read],
        root: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-dispose`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-dispose/${paramCase(id)}`,
        approvedPending: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-dispose/approve-pending`,
      },
      companyWarehouseDamagedProductRestore: {
        isVisible: true,
        nameKey: 'company:company_warehouse_damaged_product.company_warehouse_damaged_product_restore',
        icon: 'mdi:warehouse',
        permissions: [PermissionsEnum.company_companyWarehouseDamagedProductRestores_read],
        root: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-restore`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-restore/${paramCase(id)}`,
        approvedPending: `${ROOTS.DASHBOARD}/company-warehouse-damaged-product-restore/approve-pending`,
  
    },
    companyClientBalanceChange: {
      isVisible: true,
      nameKey: 'company:company_client_balance_change.company_client_balance_change',
      icon: 'mdi:cash-multiple',
      permissions: [PermissionsEnum.company_companyClientBalanceChanges_read],
      root: `${ROOTS.DASHBOARD}/company-client-balance-change`,
      details: (id: string) => `${ROOTS.DASHBOARD}/company-client-balance-change/${id}`,
      },
      companyBalanceChange: {
        isVisible: true,
        nameKey: 'company:company_balance_change.company_balance_change',
        icon: 'mdi:company',
        permissions: [PermissionsEnum.company_companyBalanceChanges_read],
        root: `${ROOTS.DASHBOARD}/company-balance-change`,
        details: (id: string) => `${ROOTS.DASHBOARD}/company-balance-change/${id}`,
        },
        companyStockChangeLog: {
          isVisible: true,
          nameKey: 'company:company_stock_change_log.company_stock_change_log',
          icon: 'mdi:company',
          permissions: [PermissionsEnum.company_companyStockChangeLogs_read],
          root: `${ROOTS.DASHBOARD}/company-stock-change-log`,
          details: (id: string) => `${ROOTS.DASHBOARD}/company-stock-change-log/${id}`,
          },
    },
    /* Client ******************************************************************* */
    client: {
      isVisible: true,
        nameKey: 'client:client.clients',
      icon: 'mdi:user',
      permissions: [PermissionsEnum.client_clients_read],
      
      client: {
        isVisible: true,
        nameKey: 'client:client.clients',
        icon: 'mdi:user',
        permissions: [PermissionsEnum.client_clients_read],
        root: `${ROOTS.DASHBOARD}/client`,
        new: `${ROOTS.DASHBOARD}/client/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/client/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/client/${id}/edit`,
      },
      clientCategory: {
        isVisible: true,
        nameKey: 'client:client_category.client_categories',
        icon: 'mdi:user',
        permissions: [PermissionsEnum.client_clientCategories_read],
        root: `${ROOTS.DASHBOARD}/client-category`,
        new: `${ROOTS.DASHBOARD}/client-category/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/client-category/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/client-category/${id}/edit`,
      },
      clientChain: {
        isVisible: true,
        nameKey: 'client:client_chain.client_chains',
        icon: 'mdi:link',
        permissions: [PermissionsEnum.client_clientChains_read],
        root: `${ROOTS.DASHBOARD}/client-chain`,
        new: `${ROOTS.DASHBOARD}/client-chain/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/client-chain/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/client-chain/${id}/edit`,
      },
      clientPosition: {
        isVisible: true,
        nameKey: 'client:client_position.client_positions',
        icon: 'mdi:user',
        permissions: [PermissionsEnum.client_clientPositions_read],
        root: `${ROOTS.DASHBOARD}/client-position`,
        new: `${ROOTS.DASHBOARD}/client-position/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/client-position/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/client-position/${id}/edit`,
      },
    },

    /* Product ******************************************************************* */
    product: {
      isVisible: true,
      nameKey: 'product:product.products',
      icon: 'mdi:cube',
      permissions: [PermissionsEnum.product_products_read],
      stock: {
        isVisible: true,
        nameKey: 'product:product_stock.product_stocks',
        icon: 'mdi:warehouse',
        permissions: [PermissionsEnum.product_products_read],
        root: `${ROOTS.DASHBOARD}/stock`,
        cards: `${ROOTS.DASHBOARD}/stock/cards`,
      },
      productBrand: {
        isVisible: true,
        nameKey: 'product:product_brand.product_brands',
        icon: 'mdi:tag',
        permissions: [PermissionsEnum.product_productBrands_read],
        root: `${ROOTS.DASHBOARD}/product-brand`,
        new: `${ROOTS.DASHBOARD}/product-brand/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/product-brand/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/product-brand/${id}/edit`,
        reorder: `${ROOTS.DASHBOARD}/product-brand/reorder`,
      },
      product: {
        isVisible: true,
        nameKey: 'product:product.products',
        icon: 'mdi:cube',
        permissions: [PermissionsEnum.product_products_read],
        productSize: {
          isVisible: true,
          nameKey: 'product:product_size.product_sizes',
          icon: 'mdi:product',
          permissions: [PermissionsEnum.product_productSizes_read],
          productPackage: { 
            isVisible: true,
            nameKey: 'product:product_package.product_packages',
            icon: 'mdi:product',
            permissions: [PermissionsEnum.product_productPackages_read],
            root: (productId: string, productSizeId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}`,
            new: (productId: string, productSizeId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}/product-package/new`,
            details: (productId: string, productSizeId: string, productPackageId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}/product-package/${productPackageId}`,
            edit: (productId: string, productSizeId: string, productPackageId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}/product-package/${productPackageId}/edit`,
            reorder: (productId: string, productSizeId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}/product-package/reorder`,
          },
          productVariant: {
            isVisible: true,
            nameKey: 'product:product_variant.product_variants',
            icon: 'mdi:product',
            permissions: [PermissionsEnum.product_productVariants_read],
            root: (productId: string, productSizeId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}`,
            new: (productId: string, productSizeId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}/product-variant/new`,
            details: (productId: string, productSizeId: string, productVariantId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}/product-variant/${productVariantId}`,
            edit: (productId: string, productSizeId: string, productVariantId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}/product-variant/${productVariantId}/edit`,
            reorder: (productId: string, productSizeId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}/product-variant/reorder`,
          },
          root: (productId: string) => `${ROOTS.DASHBOARD}/product/${productId}`,
          new: (productId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/new`,
          details: (productId: string, productSizeId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}`,
          edit: (productId: string, productSizeId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/${productSizeId}/edit`,
          reorder: (productId: string) => `${ROOTS.DASHBOARD}/product/${productId}/product-size/reorder`,
        },
        root: `${ROOTS.DASHBOARD}/product`,
        new: `${ROOTS.DASHBOARD}/product/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/product/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/product/${id}/edit`, 
        reorder: `${ROOTS.DASHBOARD}/product/reorder`,
      },
      productCategory: {
        isVisible: true,
        nameKey: 'product:product_category.product_categories',
        icon: 'mdi:cube',
        permissions: [PermissionsEnum.product_productCategories_read],
        root: `${ROOTS.DASHBOARD}/product-category`,
        new: `${ROOTS.DASHBOARD}/product-category/new`,
        reorder: `${ROOTS.DASHBOARD}/product-category/reorder`,
        details: (id: string) => `${ROOTS.DASHBOARD}/product-category/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/product-category/${id}/edit`, 
      },
      productPackageName: {
        isVisible: true,
        nameKey: 'product:product_package_name.product_package_names',
        icon: 'mdi:cube',
        permissions: [PermissionsEnum.product_productPackageNames_read],
        root: `${ROOTS.DASHBOARD}/product-package-name`,
        new: `${ROOTS.DASHBOARD}/product-package-name/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/product-package-name/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/product-package-name/${id}/edit`, 
        reorder: `${ROOTS.DASHBOARD}/product-package-name/reorder`,
      },
    },
    /* Provider ******************************************************************* */
    provider: {
      isVisible: true,
      nameKey: 'provider:provider.providers',
      icon: 'mdi:user',
      permissions: [PermissionsEnum.provider_providers_read],
      provider: {
        isVisible: true,
        nameKey: 'provider:provider.providers',
        icon: 'mdi:user',
        permissions: [PermissionsEnum.provider_providers_read],
        root: `${ROOTS.DASHBOARD}/provider`,
        new: `${ROOTS.DASHBOARD}/provider/new`,
         details: (id: string) => `${ROOTS.DASHBOARD}/provider/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/provider/${id}/edit`,
        purchaseInvoicePayment: (providerId: string) => `${ROOTS.DASHBOARD}/provider/${providerId}/purchase-invoice-payment`,
        providerWarehouse: {
          isVisible: true,
          nameKey: 'provider:provider.providerWarehouses',
          icon: 'mdi:warehouse',
          permissions: [PermissionsEnum.provider_providerWarehouses_read],
          root: (providerId: string) => `${ROOTS.DASHBOARD}/provider/${providerId}/provider-warehouse`,
          new: (providerId: string) => `${ROOTS.DASHBOARD}/provider/${providerId}/provider-warehouse/new`,
          details: (providerId: string, providerWarehouseId: string) => `${ROOTS.DASHBOARD}/provider/${providerId}/provider-warehouse/${providerWarehouseId}`,
          edit: (providerId: string, providerWarehouseId: string) => `${ROOTS.DASHBOARD}/provider/${providerId}/provider-warehouse/${providerWarehouseId}/edit`,
        },
      },
  
      providerBalanceChange: {
        isVisible: true,
        nameKey: 'provider:provider_balance_change.provider_balance_changes',
        icon: 'mdi:cash-multiple',
        permissions: [PermissionsEnum.provider_providerBalanceChanges_read],
        root: `${ROOTS.DASHBOARD}/provider-balance-change`,
        details: (id: string) => `${ROOTS.DASHBOARD}/provider-balance-change/${id}`,
        },
    },
    /* Reports******************************************************************* */
    report: {
      isVisible: true,
      nameKey: 'common:report',
      icon: 'mdi:chart-box-multiple',
      permissions: [PermissionsEnum.report_productReports],
      productReport: {
        isVisible: true,
        nameKey: 'report:product_report.product_report',
        icon: 'mdi:chart-box-multiple',
        permissions: [PermissionsEnum.report_productReports],
        root: `${ROOTS.DASHBOARD}/product-report`,
      }
    },

    /* SalesSalesInvoice ******************************************************************* */
    invoice: {
      isVisible: true,
      nameKey: 'invoice:invoice.invoices',
      icon: 'mdi:file-document-outline',
      permissions: [],
      salesInvoice: {
        isVisible: true,
        nameKey: 'invoice:sales_invoice.sales_invoices',
        icon: 'mdi:file-document-outline',
        permissions: [PermissionsEnum.invoice_salesInvoices_read],
        root: `${ROOTS.DASHBOARD}/sales-invoice`,
        new: `${ROOTS.DASHBOARD}/sales-invoice/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/sales-invoice/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/sales-invoice/${id}/edit`,
      },
      purchaseInvoice: {
        isVisible: true,
        nameKey: 'invoice:purchase_invoice.purchase_invoices',
        icon: 'mdi:cart',
        permissions: [PermissionsEnum.invoice_purchaseInvoices_read],
        root: `${ROOTS.DASHBOARD}/purchase-invoice`,
        new: `${ROOTS.DASHBOARD}/purchase-invoice/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/purchase-invoice/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/purchase-invoice/${id}/edit`,
      },
      returnInvoice: {
        isVisible: true,
        nameKey: 'invoice:return_invoice.return_invoices',
        icon: 'mdi:backup-restore',
        permissions: [PermissionsEnum.invoice_returnInvoices_read],
        root: `${ROOTS.DASHBOARD}/return-invoice`,
        new: `${ROOTS.DASHBOARD}/return-invoice/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/return-invoice/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/return-invoice/${id}/edit`,
        receiveApproved: (id: string) => `${ROOTS.DASHBOARD}/return-invoice/${id}/approve-received`,
      },
      providerReturnInvoice: {
        isVisible: true,
        nameKey: 'invoice:provider_return_invoice.provider_return_invoices',
        icon: 'mdi:file-document-outline',
        permissions: [PermissionsEnum.invoice_providerReturnInvoices_read],
        root: `${ROOTS.DASHBOARD}/provider-return-invoice`,
        new: `${ROOTS.DASHBOARD}/provider-return-invoice/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/provider-return-invoice/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/provider-return-invoice/${id}/edit`,
      },
   
      salesPaymentInvoice: {
        isVisible: true,
        nameKey: 'invoice:sales_payment_invoice.sales_payment_invoices',
        icon: 'mdi:cash-multiple',
        permissions: [PermissionsEnum.invoice_salesPaymentInvoices_read],
        root: `${ROOTS.DASHBOARD}/sales-payment-invoice`,
        new: `${ROOTS.DASHBOARD}/sales-payment-invoice/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/sales-payment-invoice/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/sales-payment-invoice/${id}/edit`,
        salesPaymentInvoiceAddonCategory: {
          isVisible: true,
          nameKey: 'invoice:sales_payment_invoice_addon_category.sales_payment_invoice_addon_categories',
          icon: 'mdi:cash-multiple',
          permissions: [PermissionsEnum.invoice_salesPaymentInvoiceAddonCategories_read],
          root: `${ROOTS.DASHBOARD}/sales-payment-invoice-addon-category`,
          new: `${ROOTS.DASHBOARD}/sales-payment-invoice-addon-category/new`,
          details: (id: string) => `${ROOTS.DASHBOARD}/sales-payment-invoice-addon-category/${paramCase(id)}`,
          edit: (id: string) => `${ROOTS.DASHBOARD}/sales-payment-invoice-addon-category/${id}/edit`,
        },
      
      },
      purchasePaymentInvoice: {
        isVisible: true,
        nameKey: 'invoice:purchase_payment_invoice.purchase_payment_invoices',
        icon: 'mdi:cash-multiple',
        permissions: [PermissionsEnum.invoice_purchasePaymentInvoices_read],
        root: `${ROOTS.DASHBOARD}/purchase-payment-invoice`,
        new: `${ROOTS.DASHBOARD}/purchase-payment-invoice/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/purchase-payment-invoice/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/purchase-payment-invoice/${id}/edit`,
        purchasePaymentInvoiceAddonCategory: {
          isVisible: true,
          nameKey: 'invoice:purchase_payment_invoice_addon_category.purchase_payment_invoice_addon_categories',
          icon: 'mdi:cash-multiple',
          permissions: [PermissionsEnum.invoice_purchasePaymentInvoiceAddonCategories_read],
          root: `${ROOTS.DASHBOARD}/purchase-payment-invoice-addon-category`,
          new: `${ROOTS.DASHBOARD}/purchase-payment-invoice-addon-category/new`,
          details: (id: string) => `${ROOTS.DASHBOARD}/purchase-payment-invoice-addon-category/${paramCase(id)}`,
          edit: (id: string) => `${ROOTS.DASHBOARD}/purchase-payment-invoice-addon-category/${id}/edit`,
        },
      },
      invoiceTag: {
        isVisible: true,
        nameKey: 'invoice:invoice_tag.invoice_tags',
        icon: 'mdi:file-document-outline',
        permissions: [PermissionsEnum.invoice_invoiceTags_read],
        root: `${ROOTS.DASHBOARD}/invoice-tag`,
        new: `${ROOTS.DASHBOARD}/invoice-tag/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/invoice-tag/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/invoice-tag/${id}/edit`,
      },
    },

    /* WorkDay ******************************************************************* */
    workDay: {
      isVisible: true,
      nameKey: 'work_day:work_day.work_day',
      icon: 'mdi:calendar-check',
      permissions: [PermissionsEnum.workDay_workDays_read],
      workDayPauseCategory: {
        isVisible: true,
        nameKey: 'work_day:work_day_pause_category.work_day_pause_categories',
        icon: 'mdi:calendar-check',
        permissions: [PermissionsEnum.workDay_workDayPauseCategories_read],
        root: `${ROOTS.DASHBOARD}/work-day-pause-category`,
        new: `${ROOTS.DASHBOARD}/work-day-pause-category/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/work-day-pause-category/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/work-day-pause-category/${id}/edit`,
      },
      workDayEndVisitCategory: {
        isVisible: true,
        nameKey: 'work_day:work_day_end_visit_category.work_day_end_visit_categories',
        icon: 'mdi:calendar-check',
        permissions: [PermissionsEnum.workDay_workDayEndVisitCategories_read],
        root: `${ROOTS.DASHBOARD}/work-day-end-visit-category`,
        new: `${ROOTS.DASHBOARD}/work-day-end-visit-category/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/work-day-end-visit-category/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/work-day-end-visit-category/${id}/edit`,
      },
      workDay: {
        isVisible: true,
        nameKey: 'work_day:work_day.work_day',
        icon: 'mdi:calendar-check',
        permissions: [PermissionsEnum.workDay_workDays_read],
        root: `${ROOTS.DASHBOARD}/work-day`,
        new: `${ROOTS.DASHBOARD}/work-day/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/work-day/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/work-day/${id}/edit`,
      },
    },


    /* Other ******************************************************************* */
    vehicle: {
      isVisible: true,
      nameKey: 'vehicle:vehicle.vehicle_management',
      icon: 'mdi:car',
      permissions: [PermissionsEnum.vehicle_vehicles_read],
      vehicle: {
        isVisible: true,
        nameKey: 'vehicle:vehicle.vehicles',
        icon: 'mdi:car',
        permissions: [PermissionsEnum.vehicle_vehicles_read],
        root: `${ROOTS.DASHBOARD}/vehicle`,
        new: `${ROOTS.DASHBOARD}/vehicle/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/vehicle/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/vehicle/${id}/edit`,
        vehicleExtra: (id: string) => `${ROOTS.DASHBOARD}/vehicle/${id}/extra`,
      },
      vehicleBrand: {
        isVisible: true,
        nameKey: 'vehicle:vehicle_brand.vehicle_brands',
        icon: 'mdi:car-multiple',
        permissions: [PermissionsEnum.vehicle_vehicleBrands_read],
        root: `${ROOTS.DASHBOARD}/vehicle-brand`,
        new: `${ROOTS.DASHBOARD}/vehicle-brand/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/vehicle-brand/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/vehicle-brand/${id}/edit`,
      },
      vehicleModel: {
        isVisible: true,
        nameKey: 'vehicle:vehicle_model.vehicle_models',
        icon: 'mdi:car-cog',
        permissions: [PermissionsEnum.vehicle_vehicleModels_read],
        root: `${ROOTS.DASHBOARD}/vehicle-model`,
        new: `${ROOTS.DASHBOARD}/vehicle-model/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/vehicle-model/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/vehicle-model/${id}/edit`,
      },
      vehicleExtraChange: {
        isVisible: true,
        nameKey: 'vehicle:vehicle_extra_change.vehicle_extra_change',
        icon: 'mdi:truck-alert-outline',
        permissions: [PermissionsEnum.vehicle_vehicleExtraChanges_read],
        root: `${ROOTS.DASHBOARD}/vehicle-extra-change`,
        details: (id: string) => `${ROOTS.DASHBOARD}/vehicle-extra-change/${paramCase(id)}`,
        receiveApproved: `${ROOTS.DASHBOARD}/vehicle-extra-change/approve-received`,
      },
    },

    /* Finance ******************************************************************* */
    finance: { 
      isVisible: true,
      nameKey: 'common:finance',
      icon: 'mdi:cash-multiple',
      permissions: [],
      safe: {
        isVisible: true,
        nameKey: 'finance:safe.safes',
        icon: 'mdi:safe',
        permissions: [PermissionsEnum.finance_safes_read],
        root: `${ROOTS.DASHBOARD}/safe`,
        new: `${ROOTS.DASHBOARD}/safe/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/safe/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/safe/${id}/edit`,
        // purchaseInvoicePayment: (safeId: string) => `${ROOTS.DASHBOARD}/safe/${safeId}/purchase-invoice-payment`, 
      },
      exchangeRate: {
        isVisible: true,
        nameKey: 'finance:exchange_rate.exchange_rates',
        icon: 'mdi:cash-multiple',
        permissions: [PermissionsEnum.finance_exchangeRates_read],
        root: `${ROOTS.DASHBOARD}/exchange-rate`,
        new: `${ROOTS.DASHBOARD}/exchange-rate/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/exchange-rate/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/exchange-rate/${id}/edit`,
        // demo: {
        //   details: `${ROOTS.DASHBOARD}/exchange-rate/${MOCK_ID}`,
        //   edit: `${ROOTS.DASHBOARD}/exchange-rate/${MOCK_ID}/edit`,
        // },
      },
      balanceChangeLog: {
        isVisible: true,
        nameKey: 'finance:balance_change_log.balance_change_logs',
        icon: 'mdi:finance',
        permissions: [PermissionsEnum.finance_balanceChangeLogs_read],
        root: `${ROOTS.DASHBOARD}/balance-change-log`,
        new: `${ROOTS.DASHBOARD}/balance-change-log/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/balance-change-log/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/balance-change-log/${id}/edit`,
      },
      expenseCategory: {
        isVisible: true,
        nameKey: 'finance:expense_category.expense_categories',
        icon: 'mdi:finance',
        permissions: [PermissionsEnum.finance_expenseCategories_read],
        root: `${ROOTS.DASHBOARD}/expense-category`,
        new: `${ROOTS.DASHBOARD}/expense-category/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/expense-category/${paramCase(id)}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/expense-category/${id}/edit`,
      },
      expense: {
        isVisible: true,
        nameKey: 'finance:expense.expenses',
        icon: 'mdi:cash-multiple',
        permissions: [PermissionsEnum.finance_expenses_read],
        root: `${ROOTS.DASHBOARD}/expense`,
        new: `${ROOTS.DASHBOARD}/expense/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/expense/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/expense/${id}/edit`,
      },
      expensePayment: {
        isVisible: true,
        nameKey: 'finance:expense_payment.expense_payments',
        icon: 'mdi:cash-multiple',
        permissions: [PermissionsEnum.finance_expensePayments_read],
        root: `${ROOTS.DASHBOARD}/expense-payment`,
        new: `${ROOTS.DASHBOARD}/expense-payment/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/expense-payment/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/expense-payment/${id}/edit`,
      },
      safeRecord: {
        isVisible: true,
        nameKey: 'finance:safe_record.safe_records',
        icon: 'mdi:safe',
        permissions: [PermissionsEnum.finance_safeRecords_read],
        root: `${ROOTS.DASHBOARD}/safe-record`,
        details: (id: string) => `${ROOTS.DASHBOARD}/safe-record/${id}`,
        },
    },

    /* Task ******************************************************************* */
    task: {
      isVisible: true,
      nameKey: 'task:delivery_task.delivery_task',
      icon: 'mdi:clipboard-list',
      permissions: [PermissionsEnum.task_deliveryTasks_read],
      deliveryTask: {
        isVisible: true,
        nameKey: 'task:delivery_task.delivery_task',
        icon: 'mdi:clipboard-list',
        permissions: [PermissionsEnum.task_deliveryTasks_read],
        root: `${ROOTS.DASHBOARD}/delivery-task`,
        new: `${ROOTS.DASHBOARD}/delivery-task/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/delivery-task/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/delivery-task/${id}/edit`,
        assign: `${ROOTS.DASHBOARD}/delivery-task/assign`,
        dispatch: (id: string) => `${ROOTS.DASHBOARD}/delivery-task/${id}/dispatch`,
        receiveApproved: (id: string, companyWarehouseId: string) => `${ROOTS.DASHBOARD}/delivery-task/${id}/approve-received/${companyWarehouseId}`,
      },
      deliveryTaskReturnCategory: {
        isVisible: true,
        nameKey: 'task:delivery_task_return_category.delivery_task_return_categories',
        icon: 'mdi:clipboard-list',
        permissions: [PermissionsEnum.task_deliveryTaskReturnCategories_read],
        root: `${ROOTS.DASHBOARD}/delivery-task-return-category`,
        new: `${ROOTS.DASHBOARD}/delivery-task-return-category/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/delivery-task-return-category/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/delivery-task-return-category/${id}/edit`,
      },
      deliveryTaskItemReturnCategory: {
        isVisible: true,
        nameKey: 'task:delivery_task_item_return_category.delivery_task_item_return_categories',
        icon: 'mdi:clipboard-list',
        permissions: [PermissionsEnum.task_deliveryTaskItemReturnCategories_read],
        root: `${ROOTS.DASHBOARD}/delivery-task-item-return-category`,
        new: `${ROOTS.DASHBOARD}/delivery-task-item-return-category/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/delivery-task-item-return-category/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/delivery-task-item-return-category/${id}/edit`,
      },
    },

    /* ******************************************************************* */
    /* ******************************************************************* */
    /* ******************************************************************* */
    /* ******************************************************************* */


    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id: string) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
  },
};
