import { logger } from "src/utils/logger";

export const initializePerformanceObserver = () => {
	// Create the performance observer for Navigation Timing API and Resource Timing API
	const performanceObserver = new PerformanceObserver((list) => { 
	  list.getEntries().forEach((entry) => {
		if (entry.entryType === 'navigation') {
		  handleNavigationEntry(entry as PerformanceNavigationTiming);
		} else if (entry.entryType === 'resource') {
		  handleResourceEntry(entry as PerformanceResourceTiming);
		}
	  });
	});
  
	// Start listening for `navigation` and `resource` entries to be dispatched
	performanceObserver.observe({ type: 'navigation', buffered: true });
	performanceObserver.observe({ type: 'resource', buffered: true });
  
	// Cleanup observer on window unload
	window.addEventListener('unload', () => {
	  performanceObserver.disconnect();
	});
  };
  
  // List of keywords to ignore
  const ignoreKeywords = ['static', 'clarity', 'assets', 'fonts', 'images', 'icons', 'favicon', 'manifest', 'googletagmanager', 'google-analytics'];
  
  // Handle navigation entries
  const handleNavigationEntry = (navEntry: PerformanceNavigationTiming) => {
	// Log Time to First Byte (TTFB) in seconds
	// logger('Time to first byte (TTFB):', (navEntry.responseStart / 1000).toFixed(2) + ' seconds', 'info');
  
	// Log Service Worker startup time in seconds
	// logger('Service Worker startup time:', ((navEntry.responseStart - navEntry.workerStart) / 1000).toFixed(2) + ' seconds', 'info');
  
	// Log Server Timing in seconds
	navEntry.serverTiming.forEach((timing) => {
	//   logger(`Server Timing - ${timing.name}:`, (timing.duration / 1000).toFixed(2) + ' seconds', 'info');
	});
  };
  
  // Handle resource entries
  const handleResourceEntry = (resEntry: PerformanceResourceTiming) => {
	// Check if the resource URL contains any of the ignore keywords
	const shouldIgnore = ignoreKeywords.some(keyword => resEntry.name.includes(keyword));
	if (shouldIgnore) {
	  return;
	}
  
	// Create an object with all relevant properties
	const resourceDetails = {
	  name: resEntry.name,
	  duration: (resEntry.duration / 1000).toFixed(2) + ' seconds',
	  size: {
		bytes: resEntry.transferSize + ' bytes',
		kilobytes: (resEntry.transferSize / 1024).toFixed(2) + ' KB'
	  },
	  startTime: (resEntry.startTime / 1000).toFixed(2) + ' seconds',
	  fetchStart: (resEntry.fetchStart / 1000).toFixed(2) + ' seconds',
	  domainLookup: (resEntry.domainLookupEnd - resEntry.domainLookupStart).toFixed(2) + ' ms',
	  connectTime: (resEntry.connectEnd - resEntry.connectStart).toFixed(2) + ' ms',
	  requestStart: (resEntry.requestStart / 1000).toFixed(2) + ' seconds',
	  responseStart: (resEntry.responseStart / 1000).toFixed(2) + ' seconds',
	  responseEnd: (resEntry.responseEnd / 1000).toFixed(2) + ' seconds',
	  encodedBodySize: {
		bytes: resEntry.encodedBodySize + ' bytes',
		kilobytes: (resEntry.encodedBodySize / 1024).toFixed(2) + ' KB'
	  },
	  decodedBodySize: {
		bytes: resEntry.decodedBodySize + ' bytes',
		kilobytes: (resEntry.decodedBodySize / 1024).toFixed(2) + ' KB'
	  }
	};
  
	// Log Resource Timing in seconds and size in KB
	// logger(`Resource ${resEntry.name} fetched in: ${(resEntry.duration / 1000).toFixed(2)} seconds, size: ${(resEntry.transferSize / 1024).toFixed(2)} KB`, resourceDetails, 'info');
  };