export enum PermissionsEnum {
        mobile_accounting = "mobile.accounting",
        report_productReports = "report.productReports",
        report_companyReports = "report.companyReports",
        website_superAdmin = "website.superAdmin",
        website_purchaseInvoices_priceEntry = "website.purchaseInvoices.priceEntry",
        website_returnInvoices_priceEntry = "website.returnInvoices.priceEntry",
        company_companies_list = "company.companies.list",
        client_clientChains_list = "client.clientChains.list",
        client_clientCategories_list = "client.clientCategories.list",
        client_clients_list = "client.clients.list",
        other_regions_list = "other.regions.list",
        other_languages_list = "other.languages.list",
        other_countries_list = "other.countries.list",
        other_currencies_list = "other.currencies.list",
        other_cities_list = "other.cities.list",
        admin_access_permissions_read = "admin.access.permissions.read",
        admin_access_permissions_create = "admin.access.permissions.create",
        admin_access_permissions_update = "admin.access.permissions.update",
        admin_access_permissions_delete = "admin.access.permissions.delete",
        admin_access_roles_read = "admin.access.roles.read",
        admin_access_roles_create = "admin.access.roles.create",
        admin_access_roles_update = "admin.access.roles.update",
        admin_access_roles_delete = "admin.access.roles.delete",
        admin_access_users_read = "admin.access.users.read",
        admin_access_users_create = "admin.access.users.create",
        admin_access_users_update = "admin.access.users.update",
        admin_access_users_delete = "admin.access.users.delete",
        admin_access_userBalanceChanges_read = "admin.access.userBalanceChanges.read",
        admin_access_userBalanceChanges_create = "admin.access.userBalanceChanges.create",
        admin_access_userBalanceChanges_update = "admin.access.userBalanceChanges.update",
        admin_access_userBalanceChanges_delete = "admin.access.userBalanceChanges.delete",
        client_clientCategories_read = "client.clientCategories.read",
        client_clientCategories_create = "client.clientCategories.create",
        client_clientCategories_update = "client.clientCategories.update",
        client_clientCategories_delete = "client.clientCategories.delete",
        client_clientChains_read = "client.clientChains.read",
        client_clientChains_create = "client.clientChains.create",
        client_clientChains_update = "client.clientChains.update",
        client_clientChains_delete = "client.clientChains.delete",
        client_clients_read = "client.clients.read",
        client_clients_create = "client.clients.create",
        client_clients_update = "client.clients.update",
        client_clients_delete = "client.clients.delete",
        client_clientContacts_read = "client.clientContacts.read",
        client_clientContacts_create = "client.clientContacts.create",
        client_clientContacts_update = "client.clientContacts.update",
        client_clientContacts_delete = "client.clientContacts.delete",
        client_clientPositions_read = "client.clientPositions.read",
        client_clientPositions_create = "client.clientPositions.create",
        client_clientPositions_update = "client.clientPositions.update",
        client_clientPositions_delete = "client.clientPositions.delete",
        company_companies_read = "company.companies.read",
        company_companies_create = "company.companies.create",
        company_companies_update = "company.companies.update",
        company_companies_delete = "company.companies.delete",
        company_companyBalanceChanges_read = "company.companyBalanceChanges.read",
        company_companyBalanceChanges_create = "company.companyBalanceChanges.create",
        company_companyBalanceChanges_update = "company.companyBalanceChanges.update",
        company_companyBalanceChanges_delete = "company.companyBalanceChanges.delete",
        company_companyWarehouseProducts_read = "company.companyWarehouseProducts.read",
        company_companyWarehouseProducts_create = "company.companyWarehouseProducts.create",
        company_companyWarehouseProducts_update = "company.companyWarehouseProducts.update",
        company_companyWarehouseProducts_resetStock = "company.companyWarehouseProducts.resetStock",
        company_companyWarehouseProducts_transferToCompanyWarehouseDamagedProduct = "company.companyWarehouseProducts.transferToCompanyWarehouseDamagedProduct",
        company_companyWarehouses_read = "company.companyWarehouses.read",
        company_companyWarehouses_create = "company.companyWarehouses.create",
        company_companyWarehouses_update = "company.companyWarehouses.update",
        company_companyWarehouses_delete = "company.companyWarehouses.delete",
        company_companyClients_read = "company.companyClients.read",
        company_companyClients_create = "company.companyClients.create",
        company_companyClients_update = "company.companyClients.update",
        company_companyClients_delete = "company.companyClients.delete",
        company_companyClients_modify_location = "company.companyClients.modify.location",
        company_companyClients_modify_contact = "company.companyClients.modify.contact",
        company_companyClients_add_contact = "company.companyClients.add.contact",
        company_companyClientPositions_read = "company.companyClientPositions.read",
        company_companyClientPositions_create = "company.companyClientPositions.create",
        company_companyClientPositions_update = "company.companyClientPositions.update",
        company_companyClientPositions_delete = "company.companyClientPositions.delete",
        company_companyClientLogs_read = "company.companyClientLogs.read",
        company_companyClientLogs_create = "company.companyClientLogs.create",
        company_companyClientLogs_update = "company.companyClientLogs.update",
        company_companyClientLogs_delete = "company.companyClientLogs.delete",
        company_companyClientPendingChanges_read = "company.companyClientPendingChanges.read",
        company_companyClientPendingChanges_create = "company.companyClientPendingChanges.create",
        company_companyClientPendingChanges_update = "company.companyClientPendingChanges.update",
        company_companyClientPendingChanges_delete = "company.companyClientPendingChanges.delete",
        company_companyClientChains_read = "company.companyClientChains.read",
        company_companyClientChains_create = "company.companyClientChains.create",
        company_companyClientChains_update = "company.companyClientChains.update",
        company_companyClientChains_delete = "company.companyClientChains.delete",
        company_companyClientCategories_read = "company.companyClientCategories.read",
        company_companyClientCategories_create = "company.companyClientCategories.create",
        company_companyClientCategories_update = "company.companyClientCategories.update",
        company_companyClientCategories_delete = "company.companyClientCategories.delete",
        company_companyClientGroups_read = "company.companyClientGroups.read",
        company_companyClientGroups_create = "company.companyClientGroups.create",
        company_companyClientGroups_update = "company.companyClientGroups.update",
        company_companyClientGroups_delete = "company.companyClientGroups.delete",
        company_warehouseDamages_read = "company.warehouseDamages.read",
        company_warehouseDamages_create = "company.warehouseDamages.create",
        company_warehouseDamages_update = "company.warehouseDamages.update",
        company_warehouseDamages_delete = "company.warehouseDamages.delete",
        company_warehouseDamagesStatus_read = "company.warehouseDamagesStatus.read",
        company_warehouseDamagesStatus_create = "company.warehouseDamagesStatus.create",
        company_warehouseDamagesStatus_update = "company.warehouseDamagesStatus.update",
        company_warehouseDamagesStatus_delete = "company.warehouseDamagesStatus.delete",
        company_companyZones_read = "company.companyZones.read",
        company_companyZones_create = "company.companyZones.create",
        company_companyZones_update = "company.companyZones.update",
        company_companyZones_delete = "company.companyZones.delete",
        company_companyPositions_read = "company.companyPositions.read",
        company_companyPositions_create = "company.companyPositions.create",
        company_companyPositions_update = "company.companyPositions.update",
        company_companyPositions_delete = "company.companyPositions.delete",
        company_companyWarehouseDamagedProducts_read = "company.companyWarehouseDamagedProducts.read",
        company_companyWarehouseDamagedProducts_create = "company.companyWarehouseDamagedProducts.create",
        company_companyWarehouseDamagedProducts_update = "company.companyWarehouseDamagedProducts.update",
        company_companyWarehouseDamagedProducts_resetStock = "company.companyWarehouseDamagedProducts.resetStock",
        company_companyWarehouseDamagedProductCategories_read = "company.companyWarehouseDamagedProductCategories.read",
        company_companyWarehouseDamagedProductCategories_create = "company.companyWarehouseDamagedProductCategories.create",
        company_companyWarehouseDamagedProductCategories_update = "company.companyWarehouseDamagedProductCategories.update",
        company_companyWarehouseDamagedProductCategories_delete = "company.companyWarehouseDamagedProductCategories.delete",
        company_companyClientBalanceChanges_read = "company.companyClientBalanceChanges.read",
        company_companyClientBalanceChanges_create = "company.companyClientBalanceChanges.create",
        company_companyClientBalanceChanges_update = "company.companyClientBalanceChanges.update",
        company_companyClientBalanceChanges_delete = "company.companyClientBalanceChanges.delete",
        company_companyEmployees_read = "company.companyEmployees.read",
        company_companyEmployees_create = "company.companyEmployees.create",
        company_companyEmployees_update = "company.companyEmployees.update",
        company_companyEmployees_delete = "company.companyEmployees.delete",
        company_companyWarehouseDamagedProductDisposeCategories_read = "company.companyWarehouseDamagedProductDisposeCategories.read",
        company_companyWarehouseDamagedProductDisposeCategories_create = "company.companyWarehouseDamagedProductDisposeCategories.create",
        company_companyWarehouseDamagedProductDisposeCategories_update = "company.companyWarehouseDamagedProductDisposeCategories.update",
        company_companyWarehouseDamagedProductDisposeCategories_delete = "company.companyWarehouseDamagedProductDisposeCategories.delete",
        company_companyWarehouseDamagedProductRestoreCategories_read = "company.companyWarehouseDamagedProductRestoreCategories.read",
        company_companyWarehouseDamagedProductRestoreCategories_create = "company.companyWarehouseDamagedProductRestoreCategories.create",
        company_companyWarehouseDamagedProductRestoreCategories_update = "company.companyWarehouseDamagedProductRestoreCategories.update",
        company_companyWarehouseDamagedProductRestoreCategories_delete = "company.companyWarehouseDamagedProductRestoreCategories.delete",
        company_companyWarehouseDamagedProductDisposes_read = "company.companyWarehouseDamagedProductDisposes.read",
        company_companyWarehouseDamagedProductDisposes_create = "company.companyWarehouseDamagedProductDisposes.create",
        company_companyWarehouseDamagedProductDisposes_update = "company.companyWarehouseDamagedProductDisposes.update",
        company_companyWarehouseDamagedProductDisposes_delete = "company.companyWarehouseDamagedProductDisposes.delete",
        company_companyWarehouseDamagedProductRestores_read = "company.companyWarehouseDamagedProductRestores.read",
        company_companyWarehouseDamagedProductRestores_create = "company.companyWarehouseDamagedProductRestores.create",
        company_companyWarehouseDamagedProductRestores_update = "company.companyWarehouseDamagedProductRestores.update",
        company_companyWarehouseDamagedProductRestores_delete = "company.companyWarehouseDamagedProductRestores.delete",
        company_companyStockChangeLogs_read = "company.companyStockChangeLogs.read",
        other_cities_read = "other.cities.read",
        other_cities_create = "other.cities.create",
        other_cities_update = "other.cities.update",
        other_cities_delete = "other.cities.delete",
        other_countries_read = "other.countries.read",
        other_countries_create = "other.countries.create",
        other_countries_update = "other.countries.update",
        other_countries_delete = "other.countries.delete",
        other_currencies_read = "other.currencies.read",
        other_currencies_create = "other.currencies.create",
        other_currencies_update = "other.currencies.update",
        other_currencies_delete = "other.currencies.delete",
        other_languages_read = "other.languages.read",
        other_languages_create = "other.languages.create",
        other_languages_update = "other.languages.update",
        other_languages_delete = "other.languages.delete",
        other_regions_read = "other.regions.read",
        other_regions_create = "other.regions.create",
        other_regions_update = "other.regions.update",
        other_regions_delete = "other.regions.delete",
        other_translations_read = "other.translations.read",
        other_translations_create = "other.translations.create",
        other_translations_update = "other.translations.update",
        other_translations_delete = "other.translations.delete",
        other_attachments_read = "other.attachments.read",
        other_attachments_create = "other.attachments.create",
        other_attachments_update = "other.attachments.update",
        other_attachments_delete = "other.attachments.delete",
        product_productBrands_read = "product.productBrands.read",
        product_productBrands_create = "product.productBrands.create",
        product_productBrands_update = "product.productBrands.update",
        product_productBrands_delete = "product.productBrands.delete",
        product_productCategories_read = "product.productCategories.read",
        product_productCategories_create = "product.productCategories.create",
        product_productCategories_update = "product.productCategories.update",
        product_productCategories_delete = "product.productCategories.delete",
        product_productPackages_read = "product.productPackages.read",
        product_productPackages_create = "product.productPackages.create",
        product_productPackages_update = "product.productPackages.update",
        product_productPackages_delete = "product.productPackages.delete",
        product_productSizes_read = "product.productSizes.read",
        product_productSizes_create = "product.productSizes.create",
        product_productSizes_update = "product.productSizes.update",
        product_productSizes_delete = "product.productSizes.delete",
        product_productVariants_read = "product.productVariants.read",
        product_productVariants_create = "product.productVariants.create",
        product_productVariants_update = "product.productVariants.update",
        product_productVariants_delete = "product.productVariants.delete",
        product_products_read = "product.products.read",
        product_products_create = "product.products.create",
        product_products_update = "product.products.update",
        product_products_delete = "product.products.delete",
        product_productPackageNames_read = "product.productPackageNames.read",
        product_productPackageNames_create = "product.productPackageNames.create",
        product_productPackageNames_update = "product.productPackageNames.update",
        product_productPackageNames_delete = "product.productPackageNames.delete",
        provider_providerWarehouses_read = "provider.providerWarehouses.read",
        provider_providerWarehouses_create = "provider.providerWarehouses.create",
        provider_providerWarehouses_update = "provider.providerWarehouses.update",
        provider_providerWarehouses_delete = "provider.providerWarehouses.delete",
        provider_providers_read = "provider.providers.read",
        provider_providers_create = "provider.providers.create",
        provider_providers_update = "provider.providers.update",
        provider_providers_delete = "provider.providers.delete",
        provider_providerBalanceChanges_read = "provider.providerBalanceChanges.read",
        provider_providerBalanceChanges_create = "provider.providerBalanceChanges.create",
        provider_providerBalanceChanges_update = "provider.providerBalanceChanges.update",
        provider_providerBalanceChanges_delete = "provider.providerBalanceChanges.delete",
        invoice_salesInvoices_read = "invoice.salesInvoices.read",
        invoice_salesInvoices_create = "invoice.salesInvoices.create",
        invoice_salesInvoices_update = "invoice.salesInvoices.update",
        invoice_salesInvoices_delete = "invoice.salesInvoices.delete",
        invoice_salesInvoices_statusUpdate = "invoice.salesInvoices.statusUpdate",
        mobile_salesInvoices_stockContext = "mobile.salesInvoices.stockContext",
        invoice_salesInvoices_salesInvoiceItemSerialNumber = "invoice.salesInvoices.salesInvoiceItemSerialNumber",
        invoice_purchaseInvoices_read = "invoice.purchaseInvoices.read",
        invoice_purchaseInvoices_create = "invoice.purchaseInvoices.create",
        invoice_purchaseInvoices_update = "invoice.purchaseInvoices.update",
        invoice_purchaseInvoices_delete = "invoice.purchaseInvoices.delete",
        invoice_salesPaymentInvoices_read = "invoice.salesPaymentInvoices.read",
        invoice_salesPaymentInvoices_create = "invoice.salesPaymentInvoices.create",
        invoice_salesPaymentInvoices_update = "invoice.salesPaymentInvoices.update",
        invoice_salesPaymentInvoices_delete = "invoice.salesPaymentInvoices.delete",
        invoice_salesPaymentInvoices_statusUpdate = "invoice.salesPaymentInvoices.statusUpdate",
        invoice_salesPaymentInvoiceAddonCategories_read = "invoice.salesPaymentInvoiceAddonCategories.read",
        invoice_salesPaymentInvoiceAddonCategories_create = "invoice.salesPaymentInvoiceAddonCategories.create",
        invoice_salesPaymentInvoiceAddonCategories_update = "invoice.salesPaymentInvoiceAddonCategories.update",
        invoice_salesPaymentInvoiceAddonCategories_delete = "invoice.salesPaymentInvoiceAddonCategories.delete",
        invoice_purchasePaymentInvoices_read = "invoice.purchasePaymentInvoices.read",
        invoice_purchasePaymentInvoices_create = "invoice.purchasePaymentInvoices.create",
        invoice_purchasePaymentInvoices_update = "invoice.purchasePaymentInvoices.update",
        invoice_purchasePaymentInvoices_delete = "invoice.purchasePaymentInvoices.delete",
        invoice_purchasePaymentInvoiceAddonCategories_read = "invoice.purchasePaymentInvoiceAddonCategories.read",
        invoice_purchasePaymentInvoiceAddonCategories_create = "invoice.purchasePaymentInvoiceAddonCategories.create",
        invoice_purchasePaymentInvoiceAddonCategories_update = "invoice.purchasePaymentInvoiceAddonCategories.update",
        invoice_purchasePaymentInvoiceAddonCategories_delete = "invoice.purchasePaymentInvoiceAddonCategories.delete",
        invoice_returnInvoices_read = "invoice.returnInvoices.read",
        invoice_returnInvoices_create = "invoice.returnInvoices.create",
        invoice_returnInvoices_update = "invoice.returnInvoices.update",
        invoice_returnInvoices_delete = "invoice.returnInvoices.delete",
        invoice_returnInvoices_statusUpdate = "invoice.returnInvoices.statusUpdate",
        invoice_returnInvoices_returnInvoiceItemSerialNumber = "invoice.returnInvoices.returnInvoiceItemSerialNumber",
        invoice_providerReturnInvoices_read = "invoice.providerReturnInvoices.read",
        invoice_providerReturnInvoices_create = "invoice.providerReturnInvoices.create",
        invoice_providerReturnInvoices_update = "invoice.providerReturnInvoices.update",
        invoice_providerReturnInvoices_delete = "invoice.providerReturnInvoices.delete",
        invoice_invoiceTags_read = "invoice.invoiceTags.read",
        invoice_invoiceTags_create = "invoice.invoiceTags.create",
        invoice_invoiceTags_update = "invoice.invoiceTags.update",
        invoice_invoiceTags_delete = "invoice.invoiceTags.delete",
        task_deliveryTasks_read = "task.deliveryTasks.read",
        task_deliveryTasks_create = "task.deliveryTasks.create",
        task_deliveryTasks_update = "task.deliveryTasks.update",
        task_deliveryTasks_delete = "task.deliveryTasks.delete",
        mobile_deliveryTasks = "mobile.deliveryTasks",
        mobile_deliveryTasks_addToVehicleStock = "mobile.deliveryTasks.addToVehicleStock",
        task_deliveryTaskReturnCategories_read = "task.deliveryTaskReturnCategories.read",
        task_deliveryTaskReturnCategories_create = "task.deliveryTaskReturnCategories.create",
        task_deliveryTaskReturnCategories_update = "task.deliveryTaskReturnCategories.update",
        task_deliveryTaskReturnCategories_delete = "task.deliveryTaskReturnCategories.delete",
        task_deliveryTaskItemReturnCategories_read = "task.deliveryTaskItemReturnCategories.read",
        task_deliveryTaskItemReturnCategories_create = "task.deliveryTaskItemReturnCategories.create",
        task_deliveryTaskItemReturnCategories_update = "task.deliveryTaskItemReturnCategories.update",
        task_deliveryTaskItemReturnCategories_delete = "task.deliveryTaskItemReturnCategories.delete",
        workDay_workDays_read = "workDay.workDays.read",
        workDay_workDays_create = "workDay.workDays.create",
        workDay_workDays_update = "workDay.workDays.update",
        workDay_workDays_delete = "workDay.workDays.delete",
        workDay_workDayPauseCategories_read = "workDay.workDayPauseCategories.read",
        workDay_workDayPauseCategories_create = "workDay.workDayPauseCategories.create",
        workDay_workDayPauseCategories_update = "workDay.workDayPauseCategories.update",
        workDay_workDayPauseCategories_delete = "workDay.workDayPauseCategories.delete",
        workDay_workDayEndVisitCategories_read = "workDay.workDayEndVisitCategories.read",
        workDay_workDayEndVisitCategories_create = "workDay.workDayEndVisitCategories.create",
        workDay_workDayEndVisitCategories_update = "workDay.workDayEndVisitCategories.update",
        workDay_workDayEndVisitCategories_delete = "workDay.workDayEndVisitCategories.delete",
        vehicle_vehicles_read = "vehicle.vehicles.read",
        vehicle_vehicles_create = "vehicle.vehicles.create",
        vehicle_vehicles_update = "vehicle.vehicles.update",
        vehicle_vehicles_delete = "vehicle.vehicles.delete",
        vehicle_vehicleModels_read = "vehicle.vehicleModels.read",
        vehicle_vehicleModels_create = "vehicle.vehicleModels.create",
        vehicle_vehicleModels_update = "vehicle.vehicleModels.update",
        vehicle_vehicleModels_delete = "vehicle.vehicleModels.delete",
        vehicle_vehicleBrands_read = "vehicle.vehicleBrands.read",
        vehicle_vehicleBrands_create = "vehicle.vehicleBrands.create",
        vehicle_vehicleBrands_update = "vehicle.vehicleBrands.update",
        vehicle_vehicleBrands_delete = "vehicle.vehicleBrands.delete",
        vehicle_vehicleExtraChanges_read = "vehicle.vehicleExtraChanges.read",
        vehicle_vehicleExtraChanges_create = "vehicle.vehicleExtraChanges.create",
        vehicle_vehicleExtraChanges_update = "vehicle.vehicleExtraChanges.update",
        vehicle_vehicleExtraChanges_delete = "vehicle.vehicleExtraChanges.delete",
        mobile_vehicleExtraChanges_dispatchApproved = "mobile.vehicleExtraChanges.dispatchApproved",
        finance_exchangeRates_read = "finance.exchangeRates.read",
        finance_exchangeRates_create = "finance.exchangeRates.create",
        finance_exchangeRates_update = "finance.exchangeRates.update",
        finance_exchangeRates_delete = "finance.exchangeRates.delete",
        finance_balanceChangeLogs_read = "finance.balanceChangeLogs.read",
        finance_expenseCategories_read = "finance.expenseCategories.read",
        finance_expenseCategories_create = "finance.expenseCategories.create",
        finance_expenseCategories_update = "finance.expenseCategories.update",
        finance_expenseCategories_delete = "finance.expenseCategories.delete",
        finance_expenses_read = "finance.expenses.read",
        finance_expenses_create = "finance.expenses.create",
        finance_expenses_update = "finance.expenses.update",
        finance_expenses_delete = "finance.expenses.delete",
        finance_expenses_statusUpdate = "finance.expenses.statusUpdate",
        finance_expensePayments_read = "finance.expensePayments.read",
        finance_expensePayments_create = "finance.expensePayments.create",
        finance_expensePayments_update = "finance.expensePayments.update",
        finance_expensePayments_delete = "finance.expensePayments.delete",
        finance_expensePayments_statusUpdate = "finance.expensePayments.statusUpdate",
        finance_safes_read = "finance.safes.read",
        finance_safes_create = "finance.safes.create",
        finance_safes_update = "finance.safes.update",
        finance_safes_delete = "finance.safes.delete",
        finance_safeRecords_read = "finance.safeRecords.read",
        finance_safeRecords_create = "finance.safeRecords.create",
        finance_safeRecords_update = "finance.safeRecords.update",
        finance_safeRecords_delete = "finance.safeRecords.delete"
    }